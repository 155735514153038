/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */

body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a { 
  text-decoration: none;
  color: #000;
}



/* Small devices - portrait iphones and ipads */
@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

