.scanner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.scanner {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.scanner-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
  animation: moveScanner 3s infinite;
}

.dropzone-text {
  font-size: 0.7em;
  color: grey;
  margin-top: 10px;
}

@keyframes moveScanner {
  0% {
    top: 0;
  }
  50% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}



.big-white-button {
  margin-top: 10px;
  padding: 10px 10px;
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid black;
  text-align: center;
  font-weight: bold;
  width: 300px;
  height: 40px;
  display: block;
}

.selfie-img {
  max-height: 300px;
}

.color-result-colors {
  margin: 10px;
}

.results-reset {
  font-size: 0.8em;
  margin-top: 20px;
}

.image-selfie {
  width: 100%;
  max-height: 200px;
  height: auto;
}

.color-result-header {
  font-size: 1.5em;
  font-weight: 600;
}

.season-header {
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  margin-top: 40px;
}

.floating-share-button {
  border: 1px solid white;
  color: black;
  padding: 10px 20px;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000; /* Ensure it appears above other elements */
  text-align: center;
  background: white;
  margin-top: 160px;
}

.product-image {
  max-width: 200px;
  max-height: 200px;
}

.season-sub-header {
  text-align: center;
  padding: 20px;
}

.camera-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: grey;
}

.camera-icon {
  font-size: 20px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  padding: 10px;
  background-color: white;
}

.powered-by {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin-top: auto;
}

.preview-image {
  height: auto; 
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.season-container {
  display: flex;
  flex-direction: column;
  min-height: 84vh;
  justify-content: center;
  align-items: center; 
}

.selfie-preview {
  height: 300px;
}

.loading-header {
  color: purple;
  text-transform: uppercase;
  font-size: 2.4em;
  letter-spacing: 0.2em;
  font-weight: 600;
  line-height: 1em;
  margin-top: 10px;
}

.results-container {
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  background: white;
}

.color-closet-header-container {
  /* flex-basis: 100%; */
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
}

.overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .overlay img {
    width: 15%;
  }
}

.product-image-container {
  height: 200px;
  width: 200px;
  display: flex;
}

.position-1 {
  position: absolute;
  top: -100px;
  left: -150px;
  animation: circleInFromLeft 2s ease-out forwards;
  justify-content: end;
  align-items: end;
}

.position-2 {
  position: absolute;
  top: 130px;
  left: -160px;
  animation: circleInFromLeft 2s ease-out forwards;
  justify-content: end;
  align-items: center;
}

.position-3 {
  position: absolute;
  top: 380px;
  left: 0px;
  animation: circleInFromLeft 2s ease-out forwards;
  justify-content: end;
  align-items: start;
}

.position-4 {
  position: absolute;
  top: -80px;
  left: 760px;
  animation: circleInFromRight 2s ease-out forwards;
  align-items: end;
}

.position-5 {
  position: absolute;
  top: 220px;
  left: 760px;
  animation: circleInFromRight 2s ease-out forwards;
  align-items: start;
}

@keyframes circleInFromLeft {
  0% {
    transform: translateX(-100%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes circleInFromRight {
  0% {
    transform: translateX(100%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}


.split-container {
  width: 100%;
}

.left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: auto;
}

.right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selfie-column, .products-column {
  flex: 1;
}

.selfie-column {
  display: flex;
  justify-content: center;
  padding: 50px;
  background: white;
  border-radius: 10px;
  height: 300px;
  width: 300px;
}

.selfie-column img {
  border-radius: 10px;
}

.selfie-image {
  max-height: 150px
}

.dropzone-component {
  border-radius: 10px;
}

.products-column {
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.color-result-leadin {
  font-size: 0.8em;
}

.results-header {
  font-weight: 700;
  margin-top: 40px;
}

.results-sub-header {
  margin-bottom: 10px;
  font-size: 0.8em;
}

.overflow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1; 
}

.powered-by-about {
  font-size: .6em;
}

.arrow-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.arrow {
  height: 100px;
}

.overlay-container {
  padding: 60px;
}

.results-below-text {
  color: white;
  margin-top: 40px;
  font-family: 'Playfair Display', serif;
  font-size: 1.4em;
  text-align: center;
}

.split-container {
  display: flex;
  justify-content: space-between;
}

.left-container, .right-container {
  width: 48%;
}

.results-big-header {
  color: white;
  text-transform: uppercase;
  font-size: 2.4em;
  letter-spacing: 0.2em;
  font-weight: 600;
  line-height: 1em;
  margin-top: 10px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .search {
    padding-top: 5px;
    padding-right: 5px;
  }

  .season-header {
    font-size: 4em;
    line-height: 1em;
    margin-top: 10px;
  }

  .color-closet-header-container {
    font-size: 2em;
  }

  .left-results-container {
    align-items: center;
  }

  .horoscope-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .selfie-column, .products-column {
    margin-bottom: 20px;
  }

  .products-column {
    background: white;
  }

  .results-container, .selfie-column {
    background: none;
  }

  .position-1, .position-2, .position-3, .position-4, .position-5 {
    display: none;
  }

  .selfie-column {
    padding: 10px;
  }

  .season-container {
    width: 100%;
  }

  .split-container {
    flex-direction: column;
    align-items: center;
  }

  .preset-color-circle {
    height: 30px;
    width: 30px;
  }

  .color-circle {
    margin-right: 5px;
  }

  .dropzone-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .left-container, .right-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .dropzone-component {
    width: 90%;
    margin: 0 auto;
    height: auto;
  }

  .preview-image {
    width: 90%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .big-white-button {
    width: 100%;
    margin-top: 10px;
  }

  .powered-by {
    text-align: center;
  }

  .results-container {
    flex-direction: column;
  }

  .overlay-container {
    padding-top: 10px;
  }

  .color-results-column {
    text-align: center;
  }

  .results-details {
    font-size: 0.9em;
  }

  .floating-share-button {
    margin-top: 10px;
  }
  
  .results-reset {
    margin-top: 0px;
  }

  .season-container {
    margin-top: 20px;
  }
}