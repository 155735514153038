.main-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-grow: 1;
    min-height: calc(100vh - 100px);
}

.text-container {
    margin: 10px;
    max-width: 50%;
}

.mockup-container { 
    text-align: center;
    padding-left: 20px;
}

.supporting-text, .download-button {
    margin-top: 20px;
    margin-bottom: 20px;
}

.header-text {
    font-size: 58px;
    line-height: 58px;
    text-align: left;
    /* text-transform: uppercase; */
    font-weight: 700;
}

.delay800 { 
    transition-delay: 800ms;
    transition-property: opacity;
    transition-duration: 2s;
}

.download-button {
    font-size: 1.125rem;
    background-color: #000;
    max-width: 200px;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 2px;
    font-weight: 700;

    &:hover {
        background-color: #222; /* Background color on hover */
    }
}

.supporting-text {
    font-size: 18px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

.apple {
    margin-right: 10px;
    height: 1.5em;
}

.download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 3px;
    /* display: none; */
}

.horizontal-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }
  
  .home-container {
    display: flex;
    align-items: center;
    background: green;
    margin: 10px;
  }
  
  .home-container.flipped {
    flex-direction: row-reverse;
  }

  .home-text {
    font-size: 1.8em;
  }
  
  .text {
    margin: 0 10px;
  }
  
  .home-image {
    height: 200px;
    width: auto; /* Adjust width to maintain aspect ratio */
}

  .split-container {
    display: flex;
    justify-content: space-between;
  }
  
  .split-container .text,
  .split-container .home-image {
    flex: 1;
    max-width: 50%;
  }

/* Large devices - laptops and desktops */
@media (max-width: 1140px) {
    .main-container {
        flex-direction: column;
        margin: 10px;
      }

    .header-text, .supporting-text, .app-link {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .responsive-mockup {
        width: 75%;
        /* background-color: purple; */
    }

    .download-button { 
        margin-bottom: 20px;
    }

    .mockup-container { 
        padding: 10px;
    }

    .text-container {
        max-width: none;
    }
  }

/* Medium devices -  */
@media (max-width: 768px) {
    .header-text {
        font-size: 50px;
    }

    .supporting-text {
        font-size: 20px;
    }

    .main-container {
        height: auto;
    }

}

/* Small devices - portrait iphones and ipads */
@media (max-width: 600px) {
    .main-container {
        height: 100%;
    }

    .header-text {
        font-size: 40px;
        line-height: 46px;
        word-wrap: break-word;
    }

    .supporting-text {
        font-size: 16px;
    }

    .mockup-container { 
        padding: 5px;
    }

}
