/* @font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
} */

.App {
  max-width: 1140px; /* Set your desired width here */
  margin: 0 auto; /* This centers the element if it has a specified width */
  padding: 0;
  box-sizing: border-box;
}

body, button, p, h1, div {
  font-family: 'Open Sans'
}

.logo {
  max-width: 120px;
  /* max-height: 100px; */
}

.new-tag {
  background-color: #0038FF;
  color: white;
  font-size: 0.75em;
  padding: 2px 4px;
  margin-left: 4px;
  border-radius: 4px;
}

#navigation-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 1000;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FFF;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

#navigation-container {
  display: flex;
  align-items: center;
}

.hamburger-icon {
  font-size: 1.5em;
}

#footer-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 30px;
}
.links-container {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.social-media-link {
  padding: 10px;
  font-size: 0.9em;
}

/* src/App.css */
.dropdown-toggle {
  display: none; /* Hide by default */
}

button.dropdown-toggle {
  border: none;
  background: none;
}

@media (max-width: 768px) {
  .dropdown-toggle {
    display: block; /* Show on mobile devices */
  }
}

.links-container {
  display: flex; /* Show links by default on larger screens */
}

@media (max-width: 768px) {
  .links-container {
    display: none; /* Hide links by default on mobile devices */
  }
  
  .links-container.open {
    background-color: #f0f0f0; 
    position: absolute;
    right: 0px;
    top: 50px;
    background: white;
    display: block;
    box-shadow: 0 6px 12px #999;
    border-radius: 4px;
    margin-right: 5px;
  }
}

.sticky-footer {
  margin-top: auto;
  position: relative;
  bottom: 0;
  text-align: center;
  padding-right: 20px;
  min-height: 40px;
}

.privacy-link {
  padding: 10px;
}

#footer-container {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
}

@media (max-width: 1140px) {
  .App {
    margin: 0;
  }

  .hide-on-mobile {
    display: none;
  }

  #footer-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-links-container {
    margin-top: 10px;
  }

  .main-container { 
    min-height: calc(100vh - 200px)
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.tiktok, .instagram {
  height: 20px;
}