.policy-container {
    margin: 10px;
}

.policy-header {
    font-size: 1.1em;
    font-weight: 600;
    margin-top: 20px; 
    margin-bottom: 5px;
}

