/* Games.css */

.recaptcha-style {
  padding: 20px;
}

.main-content {
  text-align: center;
}

.main-image-responsive {
  max-width: 100%;
  height: 250px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-item {
  flex: 1 1 auto;
  max-width: none;
  cursor: pointer;
}

.gallery-image-responsive {
  max-width: 100%;
  height: 150px
}

.selected {
  animation: selectAnimation 0.3s ease-in-out;
}

@keyframes selectAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (min-width: 768px) {
  .grid-item {
    flex: 1 1 150px;
    max-width: 150px;
  }
}