.search-container {
  padding-left: 20px;
  padding-right: 20px;
}

.header {
  font-size: 2em;
  color: #000;
  margin-bottom: 20px;
}

.circular-input {
  border-radius: 20px; 
}

.input-sketch-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.half-width {
  flex: 1;
  padding: 10px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 2px solid black;
  font-size: 1em;
  margin-left: 10px;
  border-radius: 5px;
}

.preset-colors {
  position: relative;
  padding: 10px;
}

.clear-preset-colors {
  position: relative;
  z-index: 1;
  border: none; 
  background-color: white;
  margin-left: 10px;
  color: black;
}

.preset-color-circle {
  background-color: var(--preset-color);
  height: 50px;
  width: 50px;
  border-radius: 1px;
  cursor: pointer;
  margin: 2px;
  box-sizing: border-box;
  /* border: 1px solid black; */
}

.color-circle {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-circle {
  height: 55px;
  width: 55px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.color-season-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 6px 12px #999;
  padding: 10px;
  z-index: 1000;
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columns */
  gap: 10px; /* Adjust the gap between items as needed */
  left: 50%;
  transform: translateX(-50%);
  top: 150px;
  border-radius: 4px;
}

.color-season-section {
  margin-bottom: 10px;
}

.color-grid {
  display: flex;
  flex-wrap: wrap;
}

.color-square {
  width: 20px;
  height: 20px;
  margin: 2px;
  cursor: pointer;
}

.custom-sketch-picker {
  position: absolute;
  z-index: 100000;
  margin-top: 60px;
  margin-top: 0;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.search-button {
  background: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-circle-picker .circle-picker {
  display: flex;
  flex-wrap: wrap;
}

.custom-circle-picker .circle-picker div {
  flex: 0 0 25%;
  max-width: 25%;
  box-sizing: border-box;
  padding: 5px;
}

.color-header {
  display: flex;
  justify-content: center;
  font-size: 0.8em;
}

.hexcode-picker {
  margin-top: 215px;
  margin-left: 16px;
}

.pulse-line {
  width: 100%; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #e0e0e0; /* Placeholder color */
  animation: pulse 1.5s infinite ease-in-out;
  margin-top: 5px;
}

.color-season-dropdown-container.search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-dataset {
  cursor: pointer;
}

.image-emoji-icon {
  font-size: 40px;
}

.color-closet-header-container {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.color-closet-header-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.input-button-container {
  /* display: flex;
  align-items: center; */
  /* background: #d3d3d3; */
  display: flex;
  align-items: end;
  justify-content: center;

}

.react-colorful {
  margin-top: 10px;
  position: absolute !important;
}

.deactivated-search { 
  display: flex;
}

.search-input {
  flex: 1; /* This makes the input take up the remaining space */
  margin-right: 10px; /* Optional: Add some space between the input and button */
}

.search-button:hover {
  background: #f0f0f0; /* Change background color on hover */
  color: #333; /* Change text color on hover */
}

.team-select {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #f3f2f2;
  box-shadow: 0 1px 3px rgba(212, 211, 211, 0.4);
  border-radius: 4px;
  font-size: 1em;
  width: 50%;
}

.pulse {
  width: 100px;
  height: 100px;
  background-color: grey;
  animation: pulse-animation 1.5s infinite;
}

@keyframes pulse-animation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.custom-sketch-picker {
  margin-bottom: 20px;
}

.defaults-container {
  display: flex;
  align-items: flex-end;
}

.results-grid {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
}

.result-item {
  /* border: 1px solid #eaeaea; */
  /* border-radius: 10px; */
  padding: 10px;
  width: calc(20% - 20px);
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.result-image {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  max-height: 200px;
}

.no-margin {
  margin: 5px 0;
}

.buy-button {
  /* background-color: #000;
  color: #fff; */
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
}

.buy-button:hover {
  background-color: #333;
}

.truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.retailer {
  font-size: 0.8em; /* Make the text smaller */
  text-align: left; /* Align text to the left */
  font-weight: 700;
}

.color-season-select {
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
}

.centered-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  font-weight: 600;
}

.default-dataset {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: left; /* Align text to the left */
}

.preset-colors.grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;;
}

.search {
  padding: 5px;
}

.preset-colors.horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Optional: Adds space between the circles */

}

.price {
  text-align: left;
  font-weight: 600;
  font-size: 1.1em;
}

.clickable-link {
  display: block; /* Ensure the link is block-level */
  position: relative; /* Ensure it is positioned correctly */
  z-index: 1; /* Ensure it is above other elements */
  cursor: pointer;
}

.pulse {
  display: inline-block;
  width: 150px; /* Adjust size as needed */
  height: 200px; /* Adjust size as needed */
  background-color: #f3f3f3;
  border-radius: 1px;
  animation: pulse 1.5s infinite;
}

.custom-layout .react-colorful {
  padding: 16px;
  border-radius: 12px;
  /* background: #33333a; */
  background: white;

  box-shadow: 0 6px 12px #999;
}

.custom-layout .react-colorful__saturation {
  margin: 15px 0;
  border-radius: 5px;
  border-bottom: none;
}

.custom-layout .react-colorful__hue {
  order: -1;
}

input.hexcode-picker.custom-sketch-picker {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: 'Open Sans';
}

.subtext {
  font-size: .8em;
  color: #666;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.greyed-out {
  opacity: 0.2;
  pointer-events: none; /* Prevents interaction */
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coming-soon {
  position: relative;
  display: inline-block;
}

.coming-soon .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 10000;
  bottom: 110%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container {
  position: fixed;
  bottom: 10px; /* 10px off from the bottom */
  left: 10px;
  right: 10px;
  background-color: white; /* Change background to white */
  color: black; /* Change text color to black for better contrast */
  text-align: center;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add light border shadow */
}

.close-tooltip {
  position: absolute; /* Position the close button absolutely */
  top: 10px; /* Position it 10px from the top */
  right: 10px; /* Position it 10px from the right */
  background: none;
  border: none;
  color: black; /* Change color to black for visibility */
  font-size: 16px;
  cursor: pointer;
}

.color-circle {
  background-color: var(--hexcode-color);
  background-image: radial-gradient(circle, var(--hexcode-color) 50%, transparent 51%), 
                    conic-gradient(red, orange, yellow, green, blue, indigo, violet, red);
}

.color-circle-soon {
  background-color: var(--hexcode-color);
  background-image: radial-gradient(circle, var(--hexcode-color) 6%, transparent 70%), 
                    conic-gradient(red, orange, yellow, green, blue, indigo, violet, red);
}

.align-right-container {
  display: flex;
  justify-content: right;
}

.results-container {
  display: flex;
  flex-direction: row;
}

.coming-soon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.color-season-name {
  font-size: 0.8em;
  margin-bottom: 10px;
  margin-top: 5px;
  font-weight: 600;
}

.horoscope-container {
  font-size: 0.8em;
  font-weight: 200;
  max-width: 300px;
  margin-top: 20px;
}

.color-closet-sub-header-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.9em;
}

.small-text {
  font-size: 0.6em;
  text-align: center;
  margin-top: 5px;
}

.left-results-container {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  height: 50vh;
  flex-direction: column;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  70% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .search-container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .color-closet-sub-header-container {
    align-items: center;
    margin-top: 0px;
  }
  
  .image-emoji-icon {
    font-size: 20px;
  }
  .result-item {
    width: calc(50% - 20px) !important;
  }

  .color-circle {
    height: 40px;
    width: 40px;
  }

  .circle {
    height: 40px;
    width: 40px;
  }

  .centered-header {
    margin-bottom: 0px;
  }

  .results-container {
    margin-top: 0px !important;
  }

  .mobile-hide-br {
    display: none;
  }

  .color-closet-header-container.visible {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

}